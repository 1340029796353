import { SelectChangeEvent } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import React, { ReactNode, useContext } from 'react'
import Flag from 'react-country-flag'
import { useSelector } from 'react-redux'
import styles from './ChannelSelect.module.scss'
import ChannelContext from '../../../context/ChannelContext'
import { ChannelSiteInterface, FlowType, Type as ChannelType } from '../../../interfaces/ChannelInterface'
import { State } from '../../../services/reducers'
import SwitcherBase from '../SwitcherBase'
import { SwitcherBaseItemType } from '../SwitcherBase/SwitcherBase.types'

type Props = {
  selectedChannel?: number | 'current' | null
  name: string
  label?: string | ReactNode
  onSelected: (event: SelectChangeEvent<any>, channelSites: ChannelSiteInterface[]) => void
  classes?: {
    root?: string
  }
  disabled?: boolean
}

const ChannelSelect = (props: Props) => {
  const channel = useContext(ChannelContext)
  const allChannelSites = useSelector((state: State) => state.global.channelSites)
  let channelSites = allChannelSites.filter(
    i => (channel.current?.type === ChannelType.Opposite ? i.alt == true : i.alt !== true) && i.flowType === FlowType.Customer,
  )

  //TODO: remove this after backend changes  WF-3930
  if (channelSites.length === 0) {
    channelSites = allChannelSites
  }
  const site = channelSites.find(i => (props.selectedChannel === 'current' ? i.current === true : i.channelId === props.selectedChannel))

  const renderDetails = (item: SwitcherBaseItemType) => {
    if (!('currency' in item)) {
      return <></>
    }
    return (
      <div className={styles['box']}>
        <span>{item.currency}</span>
        <Flag countryCode={item.flag.toLocaleLowerCase()} svg className={styles['flag__icon']} alt="Country Flag" />
      </div>
    )
  }

  const onSelected = (event: SelectChangeEvent<any>) => {
    props.onSelected(event, channelSites)
  }

  return (
    <SwitcherBase
      classes={{
        root: classNames(styles['location'], props.classes?.root!),
      }}
      onSelected={onSelected}
      items={channelSites}
      value={site?.channelId || ''}
      itemValueKey="channelId"
      itemNameKey="name"
      name={props.name}
      label={props.label}
      renderExtraData={renderDetails}
      disabled={props.disabled}
    />
  )
}

export default ChannelSelect

import CategoryInterface from './CategoryInterface'
import { LanguageInterface } from './LanguageInterface'
import { NewsletterOptionInterface } from './NewsletterInterface'
import PromotionItemInterface from './PromotionItemInterface'
import { SizeGuideLabelKeys } from './SizeGuideInterface'
import { WarehouseInterface } from './WarehouseInterface'

export enum Type {
  Main = 'main',
  Opposite = 'opposite',
}

export enum THEME_NAME {
  FUNKITA = 'funkita',
  FUNKYTRUNKS = 'funkytrunks',
}

export enum FlowType {
  Unknown = 0,
  Customer = 1,
  Club = 2,
}

export const AUSTRALIA_NAMES = ['Australia', 'australia', 'Australia (Global)', 'AUSTRALIA', 'au']

export interface ChannelInterface {
  id: number
  type: Type
  code: string
  name: string
  domain: string
  frontLabel: string
  canonicalDomain: string
  path: string
  active: boolean
  isWomenShop: boolean
  theme: string
  currency: string
  language: LanguageInterface
  country: string
  newsletterOption: NewsletterOptionInterface | null
  warehouse: WarehouseInterface | null
  categories: CategoryInterface[]
  category: CategoryInterface | null
  footerCategories: CategoryInterface[]
  promotionItems: PromotionItemInterface[]
  metaTags: {
    [tag: string]: string
  }
  seoLang: string | null
  robots: string | null
  params: { [key: string]: string }[]
  flag?: string
  priority?: number
  entry: boolean
  sizeGuideColumn: SizeGuideLabelKeys | null
  flowType: FlowType
}

export interface DomainChannelsInterface {
  current: ChannelInterface
  alternate: ChannelInterface
}

export interface ChannelSiteInterface {
  url: string
  name: string
  flag: string
  language: string
  currency: string
  channelId: number
  warehouseId: number
  current: boolean
  alt?: boolean
  sizeGuideColumn: SizeGuideLabelKeys | null
  flowType: FlowType
}

export default ChannelInterface
